<template>
  <div>
    <!--    面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据看版</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片内容区域-->
    <el-card style="">
      <div class="index">
        <div class="div">
          <div>
            <p
              style="
                font-weight: bold;
                font-size: 14px;
                color: #999;
                margin-top: 24px;
                font-family: STSong;
                padding-left: 20px;
              "
            >
              用户总数:
            </p>
            <p
              style="
                font-size: 24px;
                color: #dfaa5a;
                font-weight: bold;
                margin-top: 18px;
                padding-left: 20px;
              "
            >
              {{ userCount }}人
            </p>
          </div>
        </div>
        <div class="div">
          <div>
            <p
              style="
                font-weight: bold;
                font-size: 14px;
                color: #999;
                margin-top: 24px;
                padding-left: 20px;
              "
            >
              订单总数:
            </p>
            <p
              style="
                font-size: 24px;
                color: #c66956;
                font-weight: bold;
                margin-top: 18px;
                padding-left: 20px;
              "
            >
              {{ totalOrderCount }}单
            </p>
          </div>
        </div>
        <div class="div">
          <div>
            <p
              style="
                font-weight: bold;
                font-size: 14px;
                color: #999;
                margin-top: 24px;
                padding-left: 20px;
              "
            >
              今日订单数量（已成交）:
            </p>
            <p
              style="
                font-size: 24px;
                color: #4594d5;
                font-weight: bold;
                margin-top: 18px;
                padding-left: 20px;
              "
            >
              {{ todayOrderNumbers }}单
            </p>
          </div>
        </div>
        <div class="div">
          <div>
            <p
              style="
                font-weight: bold;
                font-size: 14px;
                color: #999;
                margin-top: 24px;
                padding-left: 20px;
              "
            >
              今日订单总价（已成交）:
            </p>
            <p
              style="
                font-size: 24px;
                color: #76d697;
                font-weight: bold;
                margin-top: 18px;
                padding-left: 20px;
              "
            >
              {{ todayIncome }}元
            </p>
          </div>
        </div>
        <div class="div">
          <div>
            <p
              style="
                font-weight: bold;
                font-size: 14px;
                color: #999;
                margin-top: 24px;
                padding-left: 20px;
              "
            >
              未提现总金额:
            </p>
            <p
              style="
                font-size: 24px;
                color: #39FAD8;
                font-weight: bold;
                margin-top: 18px;
                padding-left: 20px;
              "
            >
              {{ todayIncome }}元
            </p>
          </div>
        </div>
      </div>

      <div class="indexs">
        <div class="divmain">
          <div id="main"></div>
        </div>
        <div class="divmain">
          <h3 style="margin: 2% 0 2% 2%">订单支付完成率</h3>
          <div class="pdate">
            <p>日期范围</p>
            <p
              @click="ondate(0)"
              :class="backgroundp == 0 ? 'backgroundp' : ''"
            >
              昨日
            </p>
            <p
              @click="ondate(1)"
              :class="backgroundp == 1 ? 'backgroundp' : ''"
            >
              近7日
            </p>
            <p
              @click="ondate(2)"
              :class="backgroundp == 2 ? 'backgroundp' : ''"
            >
              近30日
            </p>
          </div>
          <div id="fourth" style="height: 300px !important"></div>
          <div>
            <p class="paye">未支付金额<span style="font-size: 20px;color: red;padding:0 6px ;">{{ paye }}</span>元</p>
          </div>
        </div>
        <div class="divmain">
          <div id="last5day"></div>
        </div>
      </div>
      <div class="indexy">
        <div id="monthlyDataInfo"></div>
      </div>
    </el-card>
  </div>
</template>
  <script>
import { getOrderData } from "../../api/substitute";
import { push } from "echarts/lib/component/dataZoom/history";
export default {
  data() {
    return {
      last5daydata: [],
      last5daylist: [],
      last30daydata: [],
      monthlyDataInfodata: [],
      monthlyDataInfolist: [],
      todayIncome: "",
      todayOrderNumbers: "",
      totalOrderCount: "",
      userCount: "",
      last30daylist: [],
      time: null,
      dataValArray: 0,
      backgroundp: 0,
      paye: 666,
    };
  },
  mounted() {
  },
  created() {
    this.time = setInterval(() => {
      this.ongetOrderData();
    }, 30000);
    this.ongetOrderData();
  },
  methods: {
    // 点击切换日期
    ondate(e) {
      this.backgroundp = e;
    },
    async ongetOrderData() {
      const { data: res } = await getOrderData();
      var last30daydata = [];
      var last30daylist = [];
      res.body.last30day.forEach((element) => {
        last30daydata.push(element.day);
        last30daylist.push(element.money);
      });
      this.last30daydata = last30daydata;
      this.last30daylist = last30daylist;
      var monthlyDataInfodata = [];
      var monthlyDataInfolist = [];
      res.body.monthlyDataInfo.forEach((element) => {
        monthlyDataInfodata.push(element.day);
        monthlyDataInfolist.push(element.orderNumber);
      });
      this.monthlyDataInfodata = monthlyDataInfodata;
      this.monthlyDataInfolist = monthlyDataInfolist;
      var last5daydata = [];
      var last5daylist = [];
      for (let i = res.body.last5day.length; i > 0; i--) {
        const element = res.body.last5day[i - 1];

        last5daydata.push(element.day);
        last5daylist.push(element.money);
      }
      this.last5daydata = last5daydata;
      this.last5daylist = last5daylist;
      this.todayIncome = res.body.todayIncome;
      this.todayOrderNumbers = res.body.todayOrderNumbers;
      this.totalOrderCount = res.body.totalOrderCount;
      this.userCount = res.body.userCount;
      this.drawChart();
      this.monthlyDataInfoChart();
      this.barGraph();
      this.drawCharts();
    },
    barGraph(val) {
      var _this = this;
      //初始化图标
      var myCharts = this.$echarts.init(document.getElementById("last5day"));
      //Y轴的数据，和数据值位置一一对应

      //数据值，顺序和Y轴的名字一一对应totalCount
      var option = {
        title: {
          text: "近5日订单金额",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // trigger: "item",
          // formatter: function (params) {
          //   return `百分比:${params.data.value}<br/>数量:${params.data.totalCount}`;
          // },
        },
        //图表位置
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        //X轴
        xAxis: [
          {
            type: "value",
            max: 2000000000,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            //不显示X轴刻度线和数字
            splitLine: { show: false },
            axisLabel: { show: false },
            boundaryGap: [0, 0.001],
          },
          {
            type: "value",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            //不显示X轴刻度线和数字
            splitLine: { show: false },
            axisLabel: { show: false },
            boundaryGap: [0, 0.001],
          },
        ],
        yAxis: {
          type: "category",
          data: _this.last5daydata,
          //升序
          inverse: true,
          splitLine: { show: false },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          //key和图间距
          offset: 10,
          //动画部分
          animationDuration: 300,
          animationDurationUpdate: 300,
          //key文字大小
          nameTextStyle: {
            fontSize: 5,
          },
        },
        series: [
          {
            //柱状图自动排序，排序自动让Y轴名字跟着数据动
            realtimeSort: true,
            xAxisIndex: 1,
            name: "金额",
            type: "bar",
            data: _this.last5daylist,
            barWidth: 12,
            smooth: true,
            valueAnimation: true,
            //Y轴数字显示部分
            label: {
              normal: {
                show: true,
                position: "left",
                valueAnimation: true,
                offset: [40, -20],
                textStyle: {
                  color: "#333",
                  fontSize: 13,
                },
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: 7,
              },
              //颜色样式部分
              normal: {
                barBorderRadius: 7,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "#3977E6" },
                  { offset: 1, color: "#37BBF8" },
                ]),
              },
            },
          },
        ],
        //动画部分

        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
      };
      myCharts.setOption(option, true);
      //图表大小变动从新渲染，动态自适应
      window.addEventListener("resize", function () {
        myCharts.resize();
      });
    },

    drawChart() {
      var _this = this;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "支付金额（30天内）",
        },
        tooltip: {
          trigger: "axis",
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",

          containLabel: true,
        },

        toolbox: {
          feature: {
            //  magicType: {show: false},
            //  restore: {show: true},
            saveAsImage: { show: true },
            // dataZoom: {show: true},
            // magicType: {
            //   //动态类型切换
            //   type: ["bar", "line"],
            // },
            myTool: {
              //自定义方法 配置   我这是为了实现 饼图切换为柱状图
              show: true,
              title: "导出",
              icon: "path://M958.203854 1023.866918H65.590221A65.637783 65.637783 0 0 1 0.072874 958.229135V431.561198c0-36.191108 29.386457-65.637783 65.517347-65.637783H206.621128L180.727323 421.745639H60.291024v541.963347h903.272245V421.745639h-120.436299l-27.098168-55.822224h142.175052c36.13089 0 65.517347 29.446675 65.517346 65.577565v526.728155a65.637783 65.637783 0 0 1-65.517346 65.637783zM659.88314 230.673451L542.036221 120.654891v602.181497H481.818072V120.654891L362.586135 230.673451a36.733071 36.733071 0 0 1-59.134223-11.501667 34.806091 34.806091 0 0 1 7.888578-38.539616L485.611815 10.455677a36.733071 36.733071 0 0 1 51.245645 0l174.271325 170.176491a34.806091 34.806091 0 0 1 0 50.041283 36.913726 36.913726 0 0 1-51.245645 0z",
              lang: "数据视图",
              onclick: function () {
                var _data = [["日期", "金额"]];

                _this.last30daydata.forEach((element, i) => {
                  _data.push([_this.last30daydata[i], _this.last30daylist[i]]);
                });

                const ws = _this.$XLSX.utils.aoa_to_sheet(_data);

                /* generate workbook and add the worksheet */
                const wb = _this.$XLSX.utils.book_new();
                _this.$XLSX.utils.book_append_sheet(wb, ws, "富航代驾支付金额");

                /* save to file */
                _this.$XLSX.writeFile(wb, "富航代驾支付金额.xlsx");
              },
            },
          },
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.last30daydata,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            inside: false,
            formatter: "{value} ",
          },
        },

        series: [
          {
            type: "line",
            stack: "Total",
            data: this.last30daylist,
            itemStyle: {
              // 设置线条上点的颜色（和图例的颜色）
              normal: {
                color: "#76D697",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 订单数量趋势柱状图
    monthlyDataInfoChart() {
      var _this = this;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChartr = this.$echarts.init(
        document.getElementById("monthlyDataInfo")
      );
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "订单数量趋势（12个月订单）",
        },
        tooltip: {
          trigger: "axis",
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        toolbox: {
          feature: {
            //  magicType: {show: false},
            //  restore: {show: true},
            saveAsImage: { show: true },
            // dataZoom: {show: true},
            // magicType: {
            //   //动态类型切换
            //   type: ["bar", "line"],
            // },
            myTool: {
              //自定义方法 配置   我这是为了实现 饼图切换为柱状图
              show: true,
              title: "导出",
              icon: "path://M958.203854 1023.866918H65.590221A65.637783 65.637783 0 0 1 0.072874 958.229135V431.561198c0-36.191108 29.386457-65.637783 65.517347-65.637783H206.621128L180.727323 421.745639H60.291024v541.963347h903.272245V421.745639h-120.436299l-27.098168-55.822224h142.175052c36.13089 0 65.517347 29.446675 65.517346 65.577565v526.728155a65.637783 65.637783 0 0 1-65.517346 65.637783zM659.88314 230.673451L542.036221 120.654891v602.181497H481.818072V120.654891L362.586135 230.673451a36.733071 36.733071 0 0 1-59.134223-11.501667 34.806091 34.806091 0 0 1 7.888578-38.539616L485.611815 10.455677a36.733071 36.733071 0 0 1 51.245645 0l174.271325 170.176491a34.806091 34.806091 0 0 1 0 50.041283 36.913726 36.913726 0 0 1-51.245645 0z",
              lang: "数据视图",
              onclick: function () {
                var _data = [["月份", "订单数"]];

                _this.monthlyDataInfolist.forEach((element, i) => {
                  _data.push([
                    _this.monthlyDataInfodata[i],
                    _this.monthlyDataInfolist[i],
                  ]);
                });

                const ws = _this.$XLSX.utils.aoa_to_sheet(_data);

                /* generate workbook and add the worksheet */
                const wb = _this.$XLSX.utils.book_new();
                _this.$XLSX.utils.book_append_sheet(
                  wb,
                  ws,
                  "富航代驾订单数量趋势"
                );

                /* save to file */
                _this.$XLSX.writeFile(wb, "富航代驾订单数量趋势.xlsx");
              },
            },
          },
        },

        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: this.monthlyDataInfodata,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            inside: false,
            formatter: "{value} ",
          },
        },

        series: [
          {
            type: "bar",
            stack: "Total",
            data: this.monthlyDataInfolist,
            itemStyle: {
              // 设置线条上点的颜色（和图例的颜色）
              normal: {
                color: "#488FF7",
              },
            },
            label: {
								  normal: {
								                show: true,
								                position: "top",
								                valueAnimation: true,
								                offset: [0, 0],
								                textStyle: {
								                  color: "#333",
								                  fontSize: 14,
								                },
								              },
								            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChartr.setOption(option);
      window.addEventListener("resize", function () {
        myChartr.resize();
      });
    },
    drawCharts() {
      // 基于准备好的dom，初始化echarts实例
      var myChartop = this.$echarts.init(document.getElementById("fourth"));
      this.dataValArray = this.dataValArray + 10;
      if (this.dataValArray == 100) {
        this.dataValArray = 80;
      }
      let option = {
        title: [
          {
            text: `${this.dataValArray}%`,
            textStyle: {
              color: "#39FAD8",
              fontSize: 40,
            },
            subtext: "完成率",
            subtextStyle: {
              color: "#909090",
            },
            itemGap: -10, // 主副标题距离
            left: "center",
            top: "center",
          },
        ],
        angleAxis: {
          max: 100,
          clockwise: true, // 逆时针
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        polar: {
          center: ["50%", "50%"],
          radius: "170%", //图形大小
        },
        series: [
          {
            type: "bar",
            data: [
              {
                name: "完成率",
                value: this.dataValArray,
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(1,0,0,0,
                      [
                        {
                          offset: 0,
                          color: "#459EF9",
                        },
                        {
                          offset: 1,
                          color: "#39FAD8",
                        },
                      ]
                    ),
                  },
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 25,
            barGap: "-100%", // 两环重叠
            z: 2,
          },
          {
            // 灰色环
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "#EEF2F3",
                  shadowColor: "rgba(0, 0, 0, 0.2)",
                  
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 25,
            barGap: "-100%", // 两环重叠
            z: 1,
          },
        ],
      };

      myChartop.setOption(option);
      window.addEventListener("resize", function () {
        myChartop.resize();
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
    this.time = null;
  },
};
</script>
  <style scoped>
.paye {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
  font-weight: bold;
}
.pdate {
  width: 100% !important;
  height: 24px !important;

  font-size: 12px;
  line-height: 24px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 12px 0;
}
.pdate p {
  padding: 0 12px;
  border-radius: 90px;
  float: left;
  font-weight: bold;
  cursor: pointer;
}
.backgroundp {
  color: #ca9669;
  background: #fceee2;
}
.index .div {
  width: 19%;

  background: #fff;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.index div img {
  width: 50px;
  height: 50px;
  padding-left: 12px;
}
.index div div p {
}
.index div div {
  float: left;
  width: 276px;
  height: 100%;
}
.indexs .divmain div {
  width: 98%;
  height: 98%;
  margin: 2%;
}
.indexs .divmain {
  width: 33%;
  background: #fff;
  height: 500px;
  border-radius: 4px;
}
.indexy div {
  width: 100%;
  background: #fff;
  height: 500px;
  border-radius: 4px;
  padding: 12px;
}
.indexy {
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}
.indexs {
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}
.index {
  display: flex;
  justify-content: space-between;
}
.el-card {
  min-width: 780px;
  padding: 0px;
  background: #e7e7ee;
}
.el-card /deep/ .el-card__body {
  padding: 0;
}
</style>